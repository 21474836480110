var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-list',{attrs:{"itemLayout":"vertical","pagination":_vm.pagination,"data-source":_vm.list,"grid":{ gutter: 8, xs: 2, sm: 2, md: 2, lg: 3, xl: 4, xxl: 4 }},scopedSlots:_vm._u([{key:"renderItem",fn:function(item, index){return _c('a-list-item',{key:"item.title",on:{"click":function($event){return _vm.handleAeestDetails(item)}}},[_c('a-row',{staticClass:"list-item"},[(item.canApplyToDebt == '1')?_c('div',{staticClass:"list-dept"},[_c('img',{attrs:{"src":require("../../assets/img/dept.png"),"alt":""}})]):_vm._e(),(item.canApplyToDebt == '1')?_c('div',{staticClass:"list-dept-font"},[_c('div',[_vm._v("可用凭证抵付")])]):_vm._e(),(item.bidStage == '4' || item.bidStage == '5')?_c('div',{staticClass:"list-dept-mask"}):_vm._e(),(item.bidStage == '4')?_c('div',{staticClass:"list-dept-deal"},[_c('img',{staticStyle:{"width":"160px","height":"110px"},attrs:{"src":require("../../assets/img/deal.png"),"alt":""}})]):_vm._e(),(item.bidStage == '5')?_c('div',{staticClass:"list-dept-pass"},[_c('img',{staticStyle:{"width":"150px","height":"100px"},attrs:{"src":require("../../assets/img/pass.png"),"alt":""}})]):_vm._e(),_c('a-row',{staticStyle:{"padding":"5px 5px 0 5px","overflow":"hidden"}},[(item.cover)?_c('img',{staticClass:"list-img",attrs:{"alt":"logo","src":item.cover
              ? _vm.$getFileUrl(item.cover.split(',')[0])
              : '../../assets/img/asset_pic.jpg'}}):_c('img',{staticClass:"list-img",attrs:{"alt":"logo","src":require("../../assets/img/asset_pic.jpg")}})]),_c('a-row',{staticStyle:{"padding":"10px"}},[_c('div',{staticStyle:{"height":"55px"}},[_c('a',{staticClass:"item-title",on:{"click":function($event){return _vm.handleAeestDetails(item)}}},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(_vm._s(item.assetName)+" ")]),_vm._v(" "+_vm._s(item.assetName)+" ")],2)],1)]),(item.reservePrice)?_c('div',{staticClass:"account-book",staticStyle:{"color":"black"}},[_c('a-icon',{attrs:{"type":"account-book"}}),_c('span',[_vm._v(" "+_vm._s(item.bidStage == "1" || item.bidStage == "2" ? "起拍价" : item.bidStage == "3" ? "当前价" : item.bidStage == "4" ? "成交价" : item.bidStage == "5" ? "流拍价" : "")+" ")]),_c('span',{staticClass:"price"},[_vm._v(_vm._s(item.reservePrice))]),_vm._v(" 元 ")],1):_vm._e(),_c('div',{staticStyle:{"color":"black"}},[_c('a-icon',{attrs:{"type":"history"}}),_vm._v(" 竞价开始时间："+_vm._s(item.biddingStartTime)+" ")],1)]),_c('a-row',{staticClass:"list-buttom"},[_c('a-col',{staticClass:"list-buttom-red",attrs:{"span":12}},[(item.bidStage == '1' || item.bidStage == '2')?_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('a-statistic-countdown',{attrs:{"valueStyle":{
                fontSize: '14px',
                color: '#000',
                lineHeight: '30px',
              },"value":new Date(item.registrationEndTime).getTime(),"format":"D 天 H 时 m 分 s 秒"}})],1):(item.bidStage == '3')?_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('a-statistic-countdown',{attrs:{"valueStyle":{
                fontSize: '14px',
                color: '#000',
                lineHeight: '30px',
              },"value":new Date(item.biddingEndTime).getTime(),"format":"D 天 H 时 m 分 s 秒"}})],1):(item.bidStage == '6')?_c('div',[_c('a-statistic-countdown',{attrs:{"valueStyle":{
                fontSize: '14px',
                color: '#000',
                lineHeight: '30px',
              },"value":new Date(item.biddingStartTime).getTime(),"format":"D 天 H 时 m 分 s 秒"}})],1):(item.bidStage == '7')?_c('div',[_vm._v("暂停")]):(item.bidStage == '8')?_c('div',[_vm._v("撤拍")]):_c('div',[_vm._v("已结束")])]),_c('a-col',{staticClass:"list-buttom-gray",attrs:{"span":12}},[_vm._v(" "+_vm._s(item.bidStage_dictText)+" ")])],1)],1)],1)}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
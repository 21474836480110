import { render, staticRenderFns } from "./InvestmentList.vue?vue&type=template&id=9a75b962&scoped=true"
import script from "./InvestmentList.vue?vue&type=script&lang=js"
export * from "./InvestmentList.vue?vue&type=script&lang=js"
import style0 from "./InvestmentList.vue?vue&type=style&index=0&id=9a75b962&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a75b962",
  null
  
)

export default component.exports
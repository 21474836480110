var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-list',{attrs:{"pagination":_vm.pagination,"data-source":_vm.list,"grid":{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 2 }},scopedSlots:_vm._u([{key:"renderItem",fn:function(item, index){return _c('a-list-item',{key:"item.title",on:{"click":function($event){return _vm.handleAttractDetails(item)}}},[_c('a-row',{staticClass:"list-item"},[(item.canSupportBondOffset == '是')?_c('div',{staticClass:"list-dept"},[_c('img',{attrs:{"src":require("../../assets/img/dept.png"),"alt":""}})]):_vm._e(),(item.canSupportBondOffset == '是')?_c('div',{staticClass:"list-dept-font"},[_c('div',[_vm._v("可用凭证抵付")])]):_vm._e(),_c('a-col',{staticStyle:{"padding":"5px","overflow":"hidden"},attrs:{"span":10}},[(item.cover)?_c('img',{staticClass:"list-img",attrs:{"alt":"logo","src":item.cover
              ? _vm.$getFileUrl(item.cover.split(',')[0])
              : '../../assets/img/asset_pic.jpg'}}):_c('img',{staticClass:"list-img",attrs:{"alt":"logo","src":require("../../assets/img/asset_pic.jpg")}})]),_c('a-col',{staticClass:"list-content",attrs:{"span":14}},[_c('a-row',{staticStyle:{"padding":"10px"}},[_c('div',{staticStyle:{"height":"55px"}},[_c('a',{staticClass:"item-title"},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(_vm._s(item.courtshipName)+" ")]),_vm._v(" "+_vm._s(item.courtshipName)+" ")],2)],1)]),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('a-icon',{attrs:{"type":"home"}}),_vm._v(" 发布机构："+_vm._s(item.publishingAgency)+" ")],1),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('a-icon',{attrs:{"type":"tags"}}),_vm._v(" 招商类型："+_vm._s(item.courtshipType_dictText)+" ")],1),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('a-icon',{attrs:{"type":"history"}}),_vm._v(" 招商时间："+_vm._s(item.createTime)+" ")],1)]),_c('a-row',{staticClass:"list-buttom"},[_c('a-col',{staticClass:"list-buttom-red",attrs:{"span":12}},[_c('span',[_vm._v("还剩  ")]),_c('a-statistic-countdown',{attrs:{"valueStyle":{
                fontSize: '14px',
                color: '#000',
                lineHeight: '30px',
              },"value":new Date(item.createTime).getTime(),"format":"D 天 H 时 m 分 s 秒"}})],1),_c('a-col',{staticClass:"list-buttom-gray",attrs:{"span":12}},[(item.courtshipStatus == '1')?_c('span',[_vm._v("正在招商")]):_c('span',[_vm._v("招商结束")])])],1)],1)],1)],1)}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }